// components
import Seo from 'src/components/Seo';
// hooks
import useStartupsMetaData from 'src/cms/data/startups/useStartupsMetaData';
// types
import type { HeadFC } from 'gatsby';

export const Head: HeadFC = ({ location }) => {
  const startupsMetaData = useStartupsMetaData();

  console.log(startupsMetaData, 'startupsMetaData1337');

  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={startupsMetaData?.title}
      description={startupsMetaData?.description}
      schema={startupsMetaData?.schema}
    />
  );
};

export { default } from './Startups';
